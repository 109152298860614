import React from "react";
import {getRoutes} from "./RouteHandler";
import {
    AccountBalance,
    BrightnessAuto,
    Business,
    Factory,
    PieChart,
    Settings,
    Store,
    Storefront,
    SupportAgent,
    Warehouse
} from '@mui/icons-material';

const menuHandler = [
    {
        id: 'buy',
        label: 'Achat',
        icon: <Factory/>,
        children: [
            {id: 'buy_product_list', label: 'Articles'},
            {id: 'buy_product_manage_weight_list', label: 'Articles entrepot'},
            {id: 'buy_purveyor_list', label: 'Fournisseurs'},
            {id: 'buy_order_purveyor_list', label: 'Commandes fournisseur'},
        ]
    },
    {
        id: 'sale',
        label: 'Vente',
        icon: <Storefront/>,
        children: [
            {id: 'sale_client_list', label: 'Clients'},
            {id: 'sale_promo_list', label: 'Codes promo'},
            {id: 'sale_order_client_list', label: 'Commandes Client'},
            {
                id: 'sale_order_client_list_by_status',
                label: 'Commandes Terminées',
                params: {'orderClientState': 6},
                key: 'sale_order_client_list_by_status_6'
            },
            {
                id: 'sale_order_client_list_by_status',
                label: 'Commandes En Transit',
                params: {'orderClientState': 5},
                key: 'sale_order_client_list_by_status_5'
            },
            {
                id: 'sale_order_client_list_by_status',
                label: 'Commandes Confirmées',
                params: {'orderClientState': 2},
                key: 'sale_order_client_list_by_status_2'
            },
            {
                id: 'sale_order_client_list_by_status',
                label: 'Commandes En Litige',
                params: {'orderClientState': 7},
                key: 'sale_order_client_list_by_status_7'
            },
            {
                id: 'sale_order_client_list_by_status',
                label: 'Commandes En Préparation',
                params: {'orderClientState': 3},
                key: 'sale_order_client_list_by_status_3'
            },
        ]
    },
    {
        id: 'inventory_management',
        label: 'Gestion des stocks',
        icon: <Warehouse/>,
        children: [
            {id: 'inventory_management_deposit_list', label: 'Dépôts'},
            {id: 'inventory_management_inventory_list', label: 'Inventaires'},
            {id: 'inventory_management_valorisation_stock_list', label: 'Valorisation des stocks'},
            {id: 'inventory_management_receipt_order_purveyor_list', label: 'Réceptions fournisseur'},
            {id: 'inventory_management_preparer_manager', label: 'Manager préparateur'},
            {id: 'inventory_management_preparer', label: 'Préparation commande'},
            {id: 'inventory_management_return_order', label: 'Retour commande'},
            {id: 'inventory_management_movement_list', label: 'Mouvements'},
        ]
    },
    {
        id: 'client_service',
        label: 'Service client',
        icon: <SupportAgent/>,
        children: [
            {id: 'client_service_order_client_litige_list', label: 'Litiges Client'},
        ]
    }, {
        id: 'billing',
        label: 'Facturation',
        icon: <AccountBalance/>,
        children: [
            {id: 'billing_invoice_list', label: 'Factures Client'},
            {id: 'billing_credit_list', label: 'Avoirs Client'},
            {id: 'billing_refund_list', label: 'Remboursements Client'},
        ]
    },
    {
        id: 'shop_management',
        label: 'Gestion Boutique',
        icon: <Store/>,
        children: [
            {id: 'shop_management_shop_list', label: 'Boutiques'},
            {id: 'shop_management_shop_closing_list', label: 'Fermetures caisse'},
            {id: 'shop_management_shop_region_list', label: 'Régions'},
            {id: 'shop_management_shop_list_reappro', label: 'Réapprovisionnement'},
        ]
    },
    {
        id: 'setting',
        label: 'Paramétrage',
        icon: <Settings/>,
        children: [
            {id: 'setting_mark_list', label: 'Marques'},
            {id: 'setting_product_type_list', label: 'Types d\'article'},
            {id: 'setting_galenic_list', label: 'Galéniques'},
            {id: 'setting_sector_list', label: 'Secteurs'},
            {id: 'setting_pack_size_list', label: 'Conditionnement'},
            {id: 'setting_flavor_list', label: 'Parfums / Couleurs'},
            {id: 'setting_purveyor_type_list', label: 'Types de fournisseur'},
            {id: 'setting_payment_terms_list', label: 'Conditions de paiement'},
            {id: 'setting_life_cycle_list', label: 'Cycles de vie'},
            {id: 'setting_channel_list', label: 'Canaux'},
            {id: 'setting_channel_client_list', label: 'Canaux client'},
            {id: 'setting_family_list', label: 'Familles'},
            {id: 'setting_subfamily_list', label: 'Sous-familles'},
            {id: 'setting_carrier_list', label: 'Transporteurs'},
            {id: 'setting_printer_list', label: 'Imprimantes'},
            {id: 'setting_label_printer_list', label: 'Imprimantes à étiquette'},
            {id: 'setting_country_list', label: 'Pays'},
            {id: 'setting_tva_list', label: 'TVA'},
            {id: 'setting_tva_country_list', label: 'TVA par pays'},
            {id: 'setting_order_client_litige_type_list', label: 'Types de litige'},
            {id: 'setting_order_client_forwarding_list', label: 'Statut de réexpédition'},
            {id: 'setting_order_client_refund_list', label: 'Statut de remboursement'},
            {id: 'setting_user_function_list', label: 'Fonctions utilisateur'},
            {id: 'setting_shop_movement_list', label: 'Mouvements boutique'},
        ]
    },
    {
        id: 'administrator',
        label: 'Administrateur',
        icon: <BrightnessAuto/>,
        children: [
            {id: 'administrator_user_list', label: 'Utilisateurs'},
            {id: 'administrator_request_async_list', label: 'Requêtes Asynchrone'},
            {id: 'administrator_log_sylius_list', label: 'Log - Sylius'},
            {id: 'administrator_log_printer_list', label: 'Log - Imprimante'},
            {id: 'administrator_log_carrier_list', label: 'Log - Transporteur'},
        ]
    },
    {
        id: 'statistic',
        label: 'Statistiques',
        icon: <PieChart/>,
        children: [
            {id: 'statistic_marks', label: 'Marques'},
            {id: 'statistic_buy_ca', label: 'CA fournisseur'},
            {id: 'statistic_buy_portfolio_details', label: 'Portefeuille fournisseur'},
            {id: 'statistic_sale_web_payment', label: 'Encaissements Web'},
            {id: 'statistic_sale_shop_payment', label: 'Encaissements Boutique'},
            {id: 'statistic_sale_web_order_reports', label: 'Rapports Web'},
            {id: 'statistic_sale_shop_order_reports', label: 'Rapports Boutique'},
            {id: 'statistic_preparations', label: 'Préparation'},
            {id: 'statistic_trades', label: 'Ventes'},
            {id: 'statistic_trades_retail', label: 'Ventes Retail'},
        ]
    },
    {
        id: 'b2b',
        label: 'B2B',
        icon: <Business/>,
        children: [
            {id: 'b2b_client_business_list', label: 'Revendeurs'},
            {id: 'b2b_client_business_price_list', label: 'Grilles Tarifaires'},
        ]
    }
];

export const getMenu = () => {
    let routes = getRoutes();
    let menuTmp = [];

    for (let index1 in menuHandler) {
        let children = [];

        for (let index2 in menuHandler[index1].children) {
            if (routes[menuHandler[index1].children[index2].id]) {
                let c = menuHandler[index1].children[index2];
                c.path = routes[menuHandler[index1].children[index2].id].path

                if (c.params) {
                    for (let key in c.params) {
                        if (c.params[key]) {
                            let regEx = new RegExp(':' + key + '[?]?', 'gi');
                            c.path = c.path.replace(regEx, c.params[key]);
                        } else {
                            let regEx = new RegExp('/:' + key + '[?]?', 'gi');
                            c.path = c.path.replace(regEx, '');
                        }
                    }
                }

                children.push(c);
            }
        }

        if (children.length > 0) {
            let m = {
                id: menuHandler[index1].id,
                label: menuHandler[index1].label,
                icon: menuHandler[index1].icon,
            };
            m.children = children;
            menuTmp.push(m);
        }
    }

    return menuTmp;
}
