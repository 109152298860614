import ApiHandler from '../Handler/ApiHandler';

export const fetchPaymentMethodsWeb = (setOptionsPaymentMethodsWeb) => {
    ApiHandler.get({route: 'api_select_payment_method_web'}, (response) => {
        let data = [{value: null, label: 'Tous'}, ...response.data];
        setOptionsPaymentMethodsWeb(data);
    });
};

export const fetchWebPaymentTypes = (setOptionsWebPaymentTypes) => {
    ApiHandler.get({route: 'api_select_web_payment_type'}, (response) => {
        let data = [{value: null, label: 'Tous'}, ...response.data];
        setOptionsWebPaymentTypes(data);
    });
};

export const fetchShopPaymentTypes = (setOptionsShopPaymentTypes, includeAllOption = true) => {
    ApiHandler.get({route: 'api_select_shop_payment_type'}, (response) => {
        let data = response.data;

        if (includeAllOption) {
            data = [{value: null, label: 'Tous'}, ...data];
        }

        setOptionsShopPaymentTypes(data);
    });
};

export const fetchPurveyors = (setOptionsPurveyor) => {
    ApiHandler.get({route: 'api_select_purveyor'}, (response) => {
        let data = [{value: 0, label: 'Tous'}, ...response.data];
        setOptionsPurveyor(data);
    });
};

export const fetchPurveyorTypes = (setOptionsPurveyorType) => {
    ApiHandler.get({route: 'api_select_purveyor_type'}, (response) => {
        let data = [{value: 0, label: 'Tous'}, ...response.data];
        setOptionsPurveyorType(data)
    });
};

export const fetchWebChannelsStats = (setOptionsWebChannelsStats) => {
    ApiHandler.get({route: 'api_select_web_channel_stats'}, (response) => {
        let data = [{value: null, label: 'Tous'}, ...response.data];
        setOptionsWebChannelsStats(data);
    });
};
