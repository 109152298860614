import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import {Box, Grid, Slide} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {DataGrid, frFR, GridColDef, gridNumberComparator} from "@mui/x-data-grid";

import {dispatch} from "../../../App";
import ApiHandler from "../../../Handler/ApiHandler";
import {listingStyles} from "../../../Style/useStyles";
import {styleDataGridDefault} from "../../../Style/styleDataGridDefault";
import {displayErrorMessage} from "../../../Error/Errors";
import {initFormHandler} from "../../../Handler/FormHandler";
import {scrollToElement} from "../../../Util/PageManipulator";
import TitleComponent from "../../../Component/TitleComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import {momentDateComparator} from "../../../Util/SortMomentDate";
import {isAllOptionChecked} from "../../../Filter/DropdownCheckbox";
import {exportFloatFormat} from "../../../Util/ExportNumberFormatter";
import {momentDateFormatter} from "../../../Util/MomentDateFormatter";
import CustomToolbar from "../../../Component/DataGrid/CustomToolbar";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import DatePickerComponent from "../../../Component/DatePickerComponent";
import DoubleRowHeader from "../../../Component/DataGrid/DoubleRowHeader";
import ContentViewComponent from "../../../Component/ContentViewComponent";
import {cancelPreviousRequestIfConsecutive} from "../../../Util/RequestUtil";
import {CustomPagination} from "../../../Component/DataGrid/CustomPagination";
import MultipleSelectComponent from "../../../Component/MultipleSelectComponent";
import FooterWithTotalGeneral from "../../../Component/DataGrid/FooterWithTotalGeneral";
import {transformObjectToDataGridRowsArray} from "../../../Util/DataGridDataTransformer";
import {fetchWebPaymentTypes, fetchWebChannelsStats} from "../../../Filter/FetchDropdownData";
import {formatNumberToEurCurrency, formatNumberWithThousandsSeparator} from "../../../Util/NumberTool";
import FooterWith2RowsAndMultipleFields from "../../../Component/DataGrid/FooterWith2RowsAndMultipleFields";
import {disableRowSelectionOnClick, ignoreDiacritics} from "../../../Component/DataGrid/dataGridDefaultConfig";
import {
    LinearProgressMainLayoutActivate,
    LinearProgressMainLayoutDeactivate
} from "../../../Action/LinearProgressMainLayoutAction";

const urlBase = '/api/statistic/sale/web-order-reports/details/';
const exportFileName = 'stats_web_sale_order_reports';
const exportFileNameDetails = 'stats_web_sale_order_reports_details';

const columns: GridColDef[] = [
    {
        field: "channel",
        headerName: "Canal",
        minWidth: 120,
    },
    {
        field: "orderRef",
        headerName: "Cmd Référence",
        minWidth: 160,
    },
    {
        field: "paymentType",
        headerName: "Type de paiement",
        minWidth: 100,
        renderHeader: () => <DoubleRowHeader firstLineText="Type de" secondLineText="paiement"/>,
    },
    {
        field: "confirmedAt",
        headerName: "Date de confirmation",
        headerAlign: "right",
        minWidth: 110,
        align: "right",
        sortComparator: momentDateComparator,
        renderHeader: () => <DoubleRowHeader firstLineText="Date de" secondLineText="confirmation"/>,
        valueGetter: (params) => {
            return momentDateFormatter.dateTimeToFormatFr(params.value.date);
        },
    },
    {
        field: "clientFullName",
        headerName: "Client",
        flex: 2,
    },
    {
        field: "carrierHT",
        headerName: "Frais HT Port",
        headerAlign: "right",
        align: "right",
        flex: 1,
        sortComparator: gridNumberComparator,
        renderHeader: () => <DoubleRowHeader firstLineText="Frais HT" secondLineText="Port"/>,
        renderCell: (params) => {
            return formatNumberToEurCurrency(params.value);
        },
        valueFormatter: (params) => {
            return exportFloatFormat(params.value);
        }
    },
    {
        field: "paypalHT",
        headerName: "Frais Paypal HT",
        headerAlign: "right",
        align: "right",
        flex: 1,
        sortComparator: gridNumberComparator,
        renderHeader: () => <DoubleRowHeader firstLineText="Frais HT" secondLineText="Paypal"/>,
        renderCell: (params) => {
            return formatNumberToEurCurrency(params.value);
        },
        valueFormatter: (params) => {
            return exportFloatFormat(params.value);
        }
    },
    {
        field: "totalHTInitialByProducts",
        headerName: "Total Initial HT Produits",
        headerAlign: "right",
        align: "right",
        minWidth: 110,
        flex: 1,
        sortComparator: gridNumberComparator,
        renderHeader: () => <DoubleRowHeader firstLineText="TOTAL Initial" secondLineText="HT Produits"/>,
        renderCell: (params) => {
            return formatNumberToEurCurrency(params.value);
        },
        valueFormatter: (params) => {
            return exportFloatFormat(params.value);
        }
    },
    {
        field: "totalDiscountHT",
        headerName: "Total HT Remise",
        headerAlign: "right",
        align: "right",
        minWidth: 90,
        flex: 1,
        sortComparator: gridNumberComparator,
        renderHeader: () => <DoubleRowHeader firstLineText="TOTAL HT" secondLineText="Remise"/>,
        renderCell: (params) => {
            return formatNumberToEurCurrency(params.value);
        },
        valueFormatter: (params) => {
            return exportFloatFormat(params.value);
        }
    },
    {
        field: "totalTTCInitialByProducts",
        headerName: "Total Initial TTC Produits",
        headerAlign: "right",
        align: "right",
        minWidth: 110,
        flex: 1,
        sortComparator: gridNumberComparator,
        renderHeader: () => <DoubleRowHeader firstLineText="TOTAL Initial" secondLineText="TTC Produits"/>,
        renderCell: (params) => {
            return formatNumberToEurCurrency(params.value);
        },
        valueFormatter: (params) => {
            return exportFloatFormat(params.value);
        }
    },
    {
        field: "totalOrderHT",
        headerName: "Total HT",
        headerAlign: "right",
        align: "right",
        flex: 1,
        sortComparator: gridNumberComparator,
        renderHeader: () => <DoubleRowHeader firstLineText="TOTAL" secondLineText="HT"/>,
        renderCell: (params) => {
            return formatNumberToEurCurrency(params.value);
        },
        valueFormatter: (params) => {
            return exportFloatFormat(params.value);
        }
    },
    {
        field: "totalTVA",
        headerName: "Total TVA",
        headerAlign: "right",
        align: "right",
        flex: 1,
        sortComparator: gridNumberComparator,
        renderHeader: () => <DoubleRowHeader firstLineText="TOTAL" secondLineText="TVA"/>,
        renderCell: (params) => {
            return formatNumberToEurCurrency(params.value);
        },
        valueFormatter: (params) => {
            return exportFloatFormat(params.value);
        }
    },
    {
        field: "totalOrderTTC",
        headerName: "Total TTC",
        headerAlign: "right",
        align: "right",
        flex: 1,
        sortComparator: gridNumberComparator,
        renderHeader: () => <DoubleRowHeader firstLineText="TOTAL" secondLineText="TTC"/>,
        renderCell: (params) => {
            return formatNumberToEurCurrency(params.value);
        },
        valueFormatter: (params) => {
            return exportFloatFormat(params.value);
        }
    },
    {
        field: "state",
        headerName: "Statut",
    },
];

const columnsTableDetails: GridColDef[] = [
    {
        field: "orderRef",
        headerName: "Cmd Référence",
        minWidth: 160,
    },
    {
        field: "productName",
        headerName: "Nom Produit",
        minWidth: 250,
    },
    {
        field: "sku",
        headerName: "SKU",
        minWidth: 180,
    },
    {
        field: "quantityByProduct",
        headerName: "Quantité",
        align: "right",
        renderCell: (params) => (
            formatNumberWithThousandsSeparator(params.value)
        ),
        valueFormatter: (params) => {
            return params.value;
        },
    },
    {
        field: "priceOriginalHTByProduct",
        headerName: "Prix Original HT produit",
        headerAlign: "right",
        flex: 1,
        align: "right",
        sortComparator: gridNumberComparator,
        renderHeader: () => <DoubleRowHeader firstLineText="Prix Original" secondLineText="HT Produit"/>,
        renderCell: (params) => {
            return formatNumberToEurCurrency(params.value);
        },
        valueFormatter: (params) => {
            return exportFloatFormat(params.value);
        }
    },
    {
        field: "discountHTByProduct",
        headerName: "Prix HT Remise Par Produit",
        headerAlign: "right",
        flex: 1,
        align: "right",
        sortComparator: gridNumberComparator,
        renderHeader: () => <DoubleRowHeader firstLineText="Prix HT Remise" secondLineText="Par Produit"/>,
        renderCell: (params) => {
            return formatNumberToEurCurrency(params.value);
        },
        valueFormatter: (params) => {
            return exportFloatFormat(params.value);
        }
    },
    {
        field: "priceOriginalTTCByProduct",
        headerName: "Prix Original TTC Produit",
        headerAlign: "right",
        flex: 1,
        align: "right",
        sortComparator: gridNumberComparator,
        renderHeader: () => <DoubleRowHeader firstLineText="Prix Original" secondLineText="TTC Produit"/>,
        renderCell: (params) => {
            return formatNumberToEurCurrency(params.value);
        },
        valueFormatter: (params) => {
            return exportFloatFormat(params.value);
        }
    },
    {
        field: "totalHTByProduct",
        headerName: "Total HT Produit",
        headerAlign: "right",
        flex: 1,
        align: "right",
        sortComparator: gridNumberComparator,
        renderHeader: () => <DoubleRowHeader firstLineText="Total HT" secondLineText="Produit"/>,
        renderCell: (params) => {
            return formatNumberToEurCurrency(params.value);
        },
        valueFormatter: (params) => {
            return exportFloatFormat(params.value);
        }
    },
    {
        field: "totalTTCByProduct",
        headerName: "Total TTC Produit",
        headerAlign: "right",
        flex: 1,
        align: "right",
        sortComparator: gridNumberComparator,
        renderHeader: () => <DoubleRowHeader firstLineText="Total TTC" secondLineText="Produit"/>,
        renderCell: (params) => {
            return formatNumberToEurCurrency(params.value);
        },
        valueFormatter: (params) => {
            return exportFloatFormat(params.value);
        }
    },
];

function WebOrderReports() {
    const [loadingContent, setLoadingContent] = useState(false);
    const [disabledStatusButtonSearch, setDisabledStatusButtonSearch] = useState(false);
    const [breadcrumbs, setBreadcrumbs] = useState({});

    const [optionsWebChannelsStats, setOptionsWebChannelsStats] = useState([]);
    const [optionsWebPaymentTypes, setOptionsWebPaymentTypes] = useState([]);

    const [cellsTable, setCellsTable] = useState([]);

    const [formSearch, setFormSearch] = useState({
        startDate: {
            name: 'startDate',
            label: 'Date de début',
            textHelper: 'Rechercher par date de début.',
            type: 'date',
            options: {validation: ['date']}
        },
        endDate: {
            name: 'endDate',
            label: 'Date de fin',
            textHelper: 'Rechercher par date de fin.',
            type: 'date',
            options: {validation: ['date']}
        },
        channels: {
            name: 'channels',
            label: 'Canal',
            textHelper: 'Rechercher par canaux.',
            type: 'array',
            options: {}
        },
        paymentTypes: {
            name: 'paymentTypes',
            label: 'Moyen de paiement',
            textHelper: 'Rechercher par moyen de paiement.',
            type: 'array',
        },
    });

    const handlerFormSearch = initFormHandler(formSearch, setFormSearch);

    const inputSearchChange = (index, value) => {
        value = isAllOptionChecked(value)
        handlerFormSearch.setValue(index, value);
    }

    useEffect(() => {
        handlerFormSearch.start();
        fetchWebPaymentTypes(setOptionsWebPaymentTypes)
        fetchWebChannelsStats(setOptionsWebChannelsStats)

        setBreadcrumbs({
            title: 'Stats Web Rapports de commandes',
            context: 'Statistiques',
            description: '',
            links: []
        });

    }, [])

    const onSearch = () => {
        setRowsTableDetails([]);
        setDisabledStatusButtonSearch(true);
        setLoadingContent(true);

        dispatch(LinearProgressMainLayoutActivate());

        let datas = handlerFormSearch.getData();

        let filters = {
            startDate: formSearch.startDate.value ? momentDateFormatter.formatStartOfDay(formSearch.startDate.value) : '',
            endDate: formSearch.endDate.value ? momentDateFormatter.formatEndOfDay(formSearch.endDate.value) : '',
            channels: datas.channels[0] === null ? [] : datas.channels,
            paymentTypes: datas.paymentTypes[0] === null ? [] : datas.paymentTypes,
        };

        ApiHandler.get({
                route: 'api_statistic_sale_web_order_reports',
                data: filters
            },
            (response) => {
                if (response.status >= 200 && response.status < 300) {
                    const {statsWebOrderReports, totalsByPaymentType, totalGeneralOrders} = response.data;

                    setCellsTable(statsWebOrderReports);
                    setTotalsByPaymentType(totalsByPaymentType);
                    setTotalGeneral(totalGeneralOrders);
                    setDisabledStatusButtonSearch(false);
                    setLoadingContent(false);

                    dispatch(LinearProgressMainLayoutDeactivate());
                } else {
                    setDisabledStatusButtonSearch(false);
                    setLoadingContent(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    displayErrorMessage(response)
                }
            }
        );
    };

    // First Table
    const rows = transformObjectToDataGridRowsArray(cellsTable)
    const [totalsByPaymentType, setTotalsByPaymentType] = useState([]);
    const [totalGeneral, setTotalGeneral] = useState(0.0);

    const footerFirstRowFields = totalsByPaymentType.map(type => ({
        label: type.paymentType,
        value: formatNumberToEurCurrency(type.totalTTC)
    }));

    // Second Table
    const [orderDetailsTitle, setOrderDetailsTitle] = React.useState('');
    const [rowsTableDetails, setRowsTableDetails] = React.useState([]);
    const [totalByProductsOfSelectedOrder, setTotalByProductsOfSelectedOrder] = useState([]);

    let totalGeneralOrders = 0;
    if (Object.values(rows).length > 0) {
        totalGeneralOrders = totalGeneral;
    }

    let totalGeneralProductsBySelectedOrder = 0;
    if (Object.values(rowsTableDetails).length > 0) {
        totalGeneralProductsBySelectedOrder = totalByProductsOfSelectedOrder;
    }

    const refTableDetails = useRef(null);
    const abortControllerRef = useRef(null);

    useEffect(() => {
        if (rowsTableDetails.length > 0 && refTableDetails.current) {
            scrollToElement(refTableDetails);
        }
    }, [rowsTableDetails]);

    const loadOrderDetails = (params) => {
        cancelPreviousRequestIfConsecutive(abortControllerRef);

        const newAbortController = new AbortController();
        abortControllerRef.current = newAbortController;

        const route = `${urlBase}${params.row.orderRef}`

        axios.get(route, {
            withCredentials: true,
            signal: newAbortController.signal,
        })
            .then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    const {statsWebOrderDetails, totalByProductsByOrder} = response.data.data;

                    setRowsTableDetails(statsWebOrderDetails);
                    setTotalByProductsOfSelectedOrder(totalByProductsByOrder);
                } else {
                    displayErrorMessage(response);
                }
            })
            .catch((error) => {
                if (error.name === 'CanceledError') {
                    console.log("Requête en cours annulée afin d'executer uniquement la dernière requête lancée via onRowClick");
                } else {
                    console.error('Erreur de requête', error);
                }
            });

        setOrderDetailsTitle(`
            Détails de la commande du:
            "${momentDateFormatter.dateTimeToFormatFr(params.row.confirmedAt.date, 'DD/MM/YYYY')} -
            ${params.row.channel} -
            ${params.row.orderRef} -
            Total: ${formatNumberToEurCurrency(params.row.totalOrderTTC)}"
        `);
    };

    return (
        <ContentViewComponent breadcrumbs={breadcrumbs}>
            <Slide direction={'left'} in={true} {...{timeout: 750}}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ShadowBoxComponent>
                        <TitleComponent title={'Filtres'}/>
                        <Grid container spacing={1}>
                            <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                                <DatePickerComponent id={'startDate'}
                                                     handler={handlerFormSearch}
                                                     onChange={(val) => inputSearchChange('startDate', val)}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                                <DatePickerComponent id={'endDate'}
                                                     handler={handlerFormSearch}
                                                     onChange={(val) => inputSearchChange('endDate', val)}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                                <MultipleSelectComponent id={'channels'}
                                                         options={optionsWebChannelsStats}
                                                         handler={handlerFormSearch}
                                                         onChange={(val) => inputSearchChange('channels', val)}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                                <MultipleSelectComponent id={'paymentTypes'}
                                                         options={optionsWebPaymentTypes}
                                                         handler={handlerFormSearch}
                                                         onChange={(val) => inputSearchChange('paymentTypes', val)}
                                />
                            </Grid>

                            <Grid item xs={6} sm={6} md={6} lg={6} xl={2}>
                                <ButtonComponent className={listingStyles.buttonActionFirst}
                                                 label={"Rechercher"}
                                                 onClick={() => onSearch()}
                                                 disabled={disabledStatusButtonSearch}
                                                 loading={loadingContent}
                                />
                            </Grid>
                        </Grid>
                    </ShadowBoxComponent>
                </Grid>
            </Slide>
            <br/>

            <Stack spacing={2}>
                <Box sx={{height: 620, width: '100%'}}>
                    <DataGrid
                        sx={styleDataGridDefault}
                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                        ignoreDiacritics={ignoreDiacritics}
                        disableRowSelectionOnClick={disableRowSelectionOnClick}
                        columns={columns}
                        rows={rows}
                        disableColumnMenu
                        rowHeight={25}
                        slots={{
                            toolbar: () => <CustomToolbar fileName={exportFileName}/>,
                            pagination: CustomPagination,
                            footer: () => <FooterWith2RowsAndMultipleFields footerFields={footerFirstRowFields}
                                                                            totalGeneral={totalGeneralOrders}/>,
                        }}
                        initialState={{
                            pagination: {paginationModel: {pageSize: 50}},
                        }}
                        onRowClick={loadOrderDetails} {...rows}
                    />
                </Box>

                {
                    orderDetailsTitle &&
                    <Box>
                        <Alert severity="info" sx={{paddingTop: 0, paddingBottom: 0}}>{orderDetailsTitle}</Alert>
                        <DataGrid
                            sx={styleDataGridDefault}
                            ref={refTableDetails}
                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                            ignoreDiacritics={ignoreDiacritics}
                            disableRowSelectionOnClick={disableRowSelectionOnClick}
                            columns={columnsTableDetails}
                            rows={rowsTableDetails}
                            getRowId={(row: any) => row.sku}
                            disableColumnMenu
                            rowHeight={25}
                            slots={{
                                toolbar: () => <CustomToolbar fileName={exportFileNameDetails}/>,
                                pagination: CustomPagination,
                                footer: () => <FooterWithTotalGeneral
                                    totalGeneralValue={totalGeneralProductsBySelectedOrder}/>,
                            }}
                            initialState={{
                                pagination: {paginationModel: {pageSize: 50}},
                            }}
                        />
                    </Box>
                }
            </Stack>
        </ContentViewComponent>
    )
}

export default WebOrderReports;
